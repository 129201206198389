import { node } from '@/plugins/axios'
import Vue from 'vue'
const notify = params => Vue.prototype.$notify(params)

const checkDeviceCapability = async () => {
  const error = new Error('Your device does not support this function')
  if (navigator.geolocation) {
    return navigator.geolocation
  } else {
    throw error
  }
}

export const getCurrentPosition = async callback => {
  const geo = await checkDeviceCapability()
  return geo.getCurrentPosition(
    position =>
      callback({
        lat: position.coords.latitude,
        lon: position.coords.longitude
      }),
    err => {
      if (err.code === 1)
        notify('Please enable geolocation on your browser and device')
      else throw err
    }
  )
}

export const getPositionByPostcode = async ({ postcode }) => {
  try {
    const { data } = await node.get(`/misc/location/${postcode}`)
    return {
      lat: data.lat,
      lon: data.lon
    }
  } catch (err) {
    throw err
  }
}
