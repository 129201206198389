<template>
  <div class="filter-menu-dropdown card">
    <header class="card-header">
      <p class="card-header-title has-text-info">
        <span class="icon">
          <i class="fal fa-location-arrow" />
        </span>
        <span>Distance from me</span>
      </p>
      <a class="card-header-icon" aria-label="close" @click="clearMenu">
        Close
      </a>
    </header>
    <hr class="hr is-marginless" />
    <div class="card-content" v-if="location">
      <div
        class="columns is-centered is-multiline"
        :class="{ 'not-allowed has-text-grey-light': !isGeo && !isPostcode }"
      >
        <div class="column is-12">
          <p class="is-size-4">{{ radiusLabel }} miles</p>
          <p class="is-size-7">Choose a search radius by dragging the slider</p>
        </div>
        <div class="column is-11 has-text-centered">
          <vue-slider
            class="slider"
            dot-size="33"
            :min="5"
            :max="200"
            :interval="5"
            v-model="radius"
            :disabled="
              (location && !location.method) || loading || loadingListings
            "
          />
        </div>
      </div>
    </div>
    <footer class="card-footer">
      <div class="column is-12">
        <div class="columns is-vcentered">
          <div class="column is-narrow">
            <a
              class="has-text-danger"
              :disabled="loading || loadingListings"
              v-if="location && location.method"
              @click="clearLocation"
              >Reset</a
            >
            <label class="has-text-grey-light" v-else>Reset</label>
          </div>
          <div class="column is-expanded">
            <div class="field is-grouped is-grouped-right is-vcentered">
              <div class="control">
                <button
                  class="button is-valigned is-marginless"
                  :class="{
                    'is-soft': isGeo,
                    'is-outlined': !isGeo,
                    'is-loading': loading
                  }"
                  :title="isGeo ? 'Using device location' : 'Locate me'"
                  v-tippy
                  :disabled="!canLocate || loading || loadingListings"
                  @click="getLatLong"
                >
                  <span
                    class="icon"
                    :class="[isGeo ? 'has-text-white' : 'has-text-soft']"
                  >
                    <i class="fa fa-location-arrow" />
                  </span>
                </button>
              </div>
              <div class="field" v-if="postcode && isPostcode">
                <p class="is-size-6 is-uppercase">
                  <span>{{ postcode }}</span>
                  <a
                    class="icon has-text-soft"
                    title="Edit postcode"
                    v-tippy
                    @click="clearLocation"
                  >
                    <i class="fal fa-edit" />
                  </a>
                </p>
              </div>
              <div class="field has-addons" v-else>
                <div class="control is-expanded">
                  <input
                    type="text"
                    :disabled="loading || loadingListings"
                    class="input is-uppercase is-marginless"
                    placeholder="EC2A 1AF"
                    v-model="postcode"
                    @keyup.enter="getPostcode"
                  />
                </div>
                <div class="control is-expanded">
                  <button
                    class="button"
                    :disabled="loading || loadingListings"
                    title="Lookup postcode"
                    v-tippy
                    @click="getPostcode"
                  >
                    <span class="icon has-text-soft">
                      <i class="fal fa-search" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import * as LocationService from '@/services/LocationService'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'SimilarListingsDistanceFilter',
  data: () => ({
    postcode: '',
    loading: false
  }),
  components: {
    VueSlider
  },
  computed: {
    ...mapState({
      location: state =>
        state.companion.similarListings.filterBy.distance.location
    }),
    ...mapGetters({
      loadingListings: 'companion/similarListings/loading'
    }),
    radius: {
      get() {
        return this.$store.state.companion.similarListings.filterBy.distance
          .location.distance
      },
      set(distance) {
        return this.setDistance({ distance })
      }
    },
    radiusLabel() {
      return this.radius <= 5 ? '< 5' : this.radius
    },
    canLocate() {
      return navigator.geolocation
    },
    isGeo() {
      return this.location && this.location.method === 'geo'
    },
    isPostcode() {
      return this.location && this.location.method === 'postcode'
    }
  },
  watch: {
    location: {
      deep: true,
      handler: debounce(async function() {
        this.saveLocationToStorage(this.location)
        this.clearAndFetch()
      }, 500)
    }
  },
  methods: {
    ...mapActions({
      clearAndFetch: 'companion/similarListings/clearAndFetch'
    }),
    ...mapMutations({
      setLocationFromStorage:
        'companion/similarListings/filterBy/distance/setLocationFromStorage',
      saveLocationToStorage:
        'companion/similarListings/filterBy/distance/saveLocationToStorage',
      clearLocation:
        'companion/similarListings/filterBy/distance/clearLocation',
      setCoords: 'companion/similarListings/filterBy/distance/setCoords',
      setMethod: 'companion/similarListings/filterBy/distance/setMethod',
      setPostcode: 'companion/similarListings/filterBy/distance/setPostcode',
      clearMenu: 'companion/similarListings/filterBy/clearMenu'
    }),
    setDistance(distance) {
      return this.$store.commit(
        'companion/similarListings/filterBy/distance/setDistance',
        distance
      )
    },
    async getPostcode() {
      this.loading = true
      const method = 'postcode'

      try {
        const postcode = this.postcode.replace(/\s+/g, '')
        const coords = await LocationService.getPositionByPostcode({
          postcode
        })
        if (coords) {
          this.setCoords({ coords })
          this.setMethod({ method })
          this.setPostcode({ postcode })
        }
      } catch (error) {
        this.$notify(`We couldn't find a location for that postcode`)
      } finally {
        this.loading = false
      }
    },
    getLatLong() {
      const method = 'geo'
      try {
        LocationService.getCurrentPosition(coords => {
          if (coords) {
            this.setCoords({ coords })
            this.setMethod({ method })
          }
        })
      } catch (error) {
        this.$notify(`We couldn't find you. Try manually with a postcode.`)
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.not-allowed
  cursor: not-allowed
.column
  .is-narrow
    padding-right: 0px
</style>
